import $ from "jquery"

$(function() {
    /*
    const $menubutton = $("#menubutton");
    const $desktopmenu = $("#desktopmenu");
    const $body = $("body");
    $menubutton.on("click", function() {
        const newvisible = !$desktopmenu.hasClass("visible");
        $desktopmenu.toggleClass("visible", newvisible);
        $body.toggleClass("desktopmenuvisible", newvisible);
    });
    */

    const $mobilemenu = $("#mobilemenu");
    const $hamburger = $("#hamburger-mobilemenu");
    const $body = $("body");

    $hamburger.on("click", function() {
        toggleMobileMenu();
    });

    function toggleMobileMenu() {
        const mobileMenuVisible = $mobilemenu.hasClass("visible");
        if(mobileMenuVisible) {
            hideMobileMenu();
        } else {
            showMobileMenu();
        }
    }

    function showMobileMenu() {
        $mobilemenu.addClass("visible");
        $hamburger.addClass("is-active");
        $body.addClass("mobilemenuvisible");
    }

    function hideMobileMenu() {
        $mobilemenu.removeClass("visible");
        $hamburger.removeClass("is-active");
        $body.removeClass("mobilemenuvisible");
    }

    function enhanceHtml() {
        $mobilemenu.find("li.hassubmenu > a").append('<svg class="icon icon-arrowright"><use xlink:href="#icon-arrowright"></use></svg>');
        $mobilemenu.find(".backlink").prepend('<svg class="icon icon-arrowleft"><use xlink:href="#icon-arrowleft"></use></svg>');
    }
    enhanceHtml();
});
