import $ from "jquery";

//part of library

function enhancePlaceholders() {
    const $fields = $(".formfields input[type=text], .formfields input[type=email], .formfields input[type=tel], .formfields textarea");
    if($fields.length == 0) {
        return;
    }

    $fields.each(function() {
        const $field = $(this);
        const placeholder = $field.attr("placeholder");
        const cssclasses = $field.attr("class"); //copy classes up

        $field.wrap('<div class="ctrplhfield '+cssclasses+'"></div>')
        $field.before('<label>'+placeholder+'</label>');
    });
}

$(function() {
    enhancePlaceholders();    
});